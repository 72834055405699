import React, { useEffect, useState } from 'react';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  Paper, Typography, Checkbox, Button, Chip, Grid2, IconButton, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Skeleton
} from '@mui/material';
import { statusColors } from '../../../config/status';
import { useNavigate } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import axios from 'axios';
import MailIcon from '@mui/icons-material/Mail';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import CommentIcon from '@mui/icons-material/Comment';

const ApplicationSummaryTable = ({ placementData, initialApplicationData, campaignDetails }) => {
  const navigate = useNavigate();
  const [creatorDetails, setCreatorDetails] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingStatusUpdate, setIsLoadingStatusUpdate] = useState(false);
  const [commentSource, setCommentSource] = useState('')
  const [openCommentDialog, setOpenCommentDialog] = useState(false);
  const [comment, setComment] = useState('');
  const [currentPlacementId, setCurrentPlacementId] = useState(null); 
  const [applicationData, setApplicationData] = useState(initialApplicationData); // For tracking which placement is being commented on
  const [isLocked, setIsLocked] = useState(false);

  useEffect(() => {
    const user_type = localStorage.getItem('user_type');
    if (!user_type) {
      navigate('/logout');
    }
    getCreator();
  }, [navigate]);

  useEffect(() => {
       if (initialApplicationData.status === "Offered") {
      setIsLocked(true);
    }
 
  }, [initialApplicationData]);

  useEffect(() => {
    if (applicationData.status === "Offered") {
      setIsLocked(true);
    } else {
      setIsLocked(false);
    }
  }, [applicationData]);

  // Function to find the proposed fee for a given placement
  const findProposedFee = (placementId) => {
    const placement = applicationData.placementsAppliedTo.find(p => p.placementId === placementId);
    return placement ? placement.proposedFee : null;
  };

  const findPlacementStatus = (placementId) => {
    const placement = applicationData.placementsAppliedTo.find(p => p.placementId === placementId);
    return placement ? placement.status : null;
  };

  const getCreator = async () => {
    try {
      const auth = getAuth();
      const user = auth.currentUser;
      const creatorId = applicationData.creatorId;

      if (user) {
        const idToken = await user.getIdToken();
        const response = await axios.get(
          `https://ostendo.nucleotto.com/api/user/getUser?userId=${creatorId}`,
          {},
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${idToken}`,
            },
          }
        );
        console.log(response.data.result);
        setCreatorDetails(response.data.result || []); // Default to an empty array if no jobs
      }
    } catch (error) {
      console.error('Error fetching campaigns:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const getApplicationById = async (applicationId) => {
    try {
      const auth = getAuth();
      const user = auth.currentUser;
  
      if (user) {
        const idToken = await user.getIdToken();
        const response = await axios.post(
          `https://ostendo.nucleotto.com/api/application/get`, 
          {
            applicationId
          },
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${idToken}`,
            },
          }
        );
        const responseData = response.data.application;
        const applicationStatus = responseData.status;
        setApplicationData(responseData); // Set the updated application data
        if (applicationStatus === "Offered") {
          setIsLocked(true);
        }
        setIsLoadingStatusUpdate(false);
      }
    } catch (error) {
      console.error('Error fetching application by ID:', error);
    }
  };

  const handleNewMessage = async () => {
    const auth = getAuth();
    const user = auth.currentUser;
    const userId = user.uid;
    const isNewMessage = true;
    navigate('/messaging', { state: { applicationData, creatorDetails, userId, isNewMessage, campaignDetails } });
  };

// Action Handlers for Approve, Decline, and Comment
const handleApprove = (placementId) => {
  updatePlacementStatus(applicationData._id, placementId, 'Approved'); // Pass both applicationId and placementId
};

const handleDecline = (placementId) => {
  updatePlacementStatus(applicationData._id, placementId, 'Declined'); // Pass both applicationId and placementId
};

const handleOpenCommentDialog = (placementId) => {
  setCurrentPlacementId(placementId);
  setOpenCommentDialog(true);
};

const handleCommentSubmit = () => {
  updatePlacementStatus(applicationData._id, currentPlacementId, 'Pending', comment); // Ensure applicationId is passed
  setOpenCommentDialog(false);
  setComment('');
};

// Function to update the status of a placement
const updatePlacementStatus = async (applicationId, placementId, status, comment = null) => {
  setIsLoadingStatusUpdate(true);
  try {
    const auth = getAuth();
    const user = auth.currentUser;
    const idToken = await user.getIdToken();

    // Construct the payload according to the expected format
    const payload = {
      applicationId, // Ensure the applicationId is included
      updates: {
        placementId, // Placement being updated
        status, // New status (e.g., 'Approved', 'Declined', 'Pending')
        comment, // Optional comment
        commentSource,
      },
    };

    // Send the PUT request to update the placement
    await axios.put(
      'https://ostendo.nucleotto.com/api/application/update',
      payload,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${idToken}`,
        },
      }
    );

    await getApplicationById(applicationId);
  

    
    console.log('Placement status updated successfully');
  } catch (error) {
    console.error('Error updating placement status:', error);
  }
};
  
const handleLockAndOffer = () => {
  lockAndOffer(applicationData._id);
  setIsLocked(true);
  setComment('');
};

const lockAndOffer = async (applicationId) => {
    setIsLoadingStatusUpdate(true);
    try {
      const auth = getAuth();
      const user = auth.currentUser;
      const idToken = await user.getIdToken();
  
      // Construct the payload according to the expected format
      const payload = {
        applicationId, // Ensure the applicationId is included
        updates: {
          status: "Offered", // New status (e.g., 'Approved', 'Declined', 'Pending')
        },
      };
  
      // Send the PUT request to update the placement
      await axios.put(
        'https://ostendo.nucleotto.com/api/application/offer',
        payload,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${idToken}`,
          },
        }
      );
  
      await getApplicationById(applicationId);
    
      setIsLoadingStatusUpdate(false);
      
      console.log('Placement status updated successfully');
    } catch (error) {
      console.error('Error updating placement status:', error);
    }
  };
  


  return (
    <TableContainer component={Paper} sx={{ borderRadius: 4, boxShadow: 2, marginTop: 6 }}>
      <Grid2 container alignItems="center" justifyContent="space-between" sx={{ mt: 2 }}>
        <Typography variant="h6" sx={{ padding: 2, fontWeight: 'bold' }}>
          {creatorDetails.email}
        </Typography>
        <Typography variant="subtitle1" sx={{ padding: 2, fontWeight: 'bold' }}>
          Proposed: ${applicationData.placementsAppliedTo.reduce((sum, placement) => sum + placement.proposedFee, 0)} /
          Budgeted: ${placementData.reduce((sum, placement) => sum + parseFloat(placement.fee.replace(/[^0-9.-]+/g, '')) || 0, 0)}
        </Typography>
        <Grid2 item>
          <Grid2 container justifyContent="flex-end">
            <Typography variant="h6" sx={{ padding: 2, fontWeight: 'bold', color: 'ORANGE' }}>
              {applicationData.status}
            </Typography>
           
          </Grid2>
        </Grid2>
        <Grid2 item>
        {isLocked ? (
                  <>
                    </>
              ) : (
                
        <Button variant="contained" onClick={handleLockAndOffer} sx={{ marginRight: '10px', fontWeight: 'bold', color: 'ORANGE' }}>
              Lock & Offer
            </Button> )}
          <Grid2 container justifyContent="flex-end" marginRight="10px">
            <IconButton> <MailIcon onClick={handleNewMessage} color='green'/></IconButton>
          </Grid2>
        </Grid2>
      </Grid2>

      <Table>
        <TableHead>
          <TableRow>
            <TableCell><strong></strong></TableCell>
            <TableCell><strong>CAMPAIGN TYPE</strong></TableCell>
            <TableCell><strong>PLATFORM</strong></TableCell>
            <TableCell><strong>FORMAT</strong></TableCell>
            <TableCell><strong>FEE</strong></TableCell>
            <TableCell><strong>PROPOSED FEE</strong></TableCell>
            <TableCell><strong>STATUS</strong></TableCell>
            <TableCell><strong>ACTIONS</strong></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {placementData.map((placement, index) => {
            const proposedFee = findProposedFee(placement._id);
            const placementStatus = findPlacementStatus(placement._id);

            return (
              <TableRow key={index}>
                <TableCell>
                  <Checkbox
                    checked={true}
                    inputProps={{ 'aria-label': 'Select Placement' }}
                  />
                </TableCell>
                <TableCell>{placement.campaignType}</TableCell>
                <TableCell>{placement.platform}</TableCell>
                <TableCell>{placement.format}</TableCell>
                <TableCell>{`$${placement.fee}`}</TableCell>
                <TableCell>{proposedFee !== null ? `$${proposedFee}` : '-'}</TableCell>
                <TableCell>
                {isLoadingStatusUpdate ? (
                  <>
                  
                <Skeleton variant="rounded" width={50} height={30} animation="wave" />
                </>
              ) : (
               <Chip
                    label={placementStatus}
                    sx={{
                      backgroundColor: statusColors[placementStatus]?.backgroundColor || statusColors.default.backgroundColor,
                      color: statusColors[placementStatus]?.color || statusColors.default.color,
                    }}
                  />
                )}
                </TableCell>
                <TableCell>
                  <IconButton onClick={() => handleApprove(placement._id)} color="success" disabled={isLoadingStatusUpdate || isLocked}>
                    <CheckCircleIcon />
                  </IconButton>
                  <IconButton onClick={() => handleDecline(placement._id)} color="error" disabled={isLoadingStatusUpdate || isLocked}>
                    <CancelIcon />
                  </IconButton>
                  <IconButton onClick={() => handleOpenCommentDialog(placement._id)} color="primary" disabled={isLoadingStatusUpdate || isLocked}>
                    <CommentIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>

      {/* Comment Dialog */}
      <Dialog open={openCommentDialog} onClose={() => setOpenCommentDialog(false)}>
        <DialogTitle>Make Comment</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please enter your comment before submitting this placement to "Pending".
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="Comment"
            type="text"
            fullWidth
            variant="standard"
            value={comment}
            onChange={(e) => setComment(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenCommentDialog(false)}>Cancel</Button>
          <Button onClick={handleCommentSubmit} variant="contained" color="primary">
            Submit Comment
          </Button>
        </DialogActions>
      </Dialog>
    </TableContainer>
  );
};

export default ApplicationSummaryTable;
