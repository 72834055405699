// theme.js
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  typography: {
    h3: {
      fontWeight: 'bold',
      marginBottom: '24px',
    },
    body1: {
      marginTop: '16px',
    },
    body2: {
      marginTop: '16px',
    },
  },
});

export default theme;
