// components/SocialSignInButton.jsx
import React from 'react';
import { Button } from '@mui/material';


const SocialSignInButton = ({ onClick, icon, text }) => (
  <Button
    variant="contained"
    startIcon={icon}
    fullWidth
    sx={{
      ml: 1,
      py: 1.5,
      backgroundColor: 'white',
      color: 'black',
      borderRadius: '8px',
      borderColor: 'black',
      '&:hover': {
        backgroundColor: '#ebebeb',
      },
    }}
    onClick={onClick}

  >
    {text}
  </Button>
);

export default SocialSignInButton;
