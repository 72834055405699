// src/pages/AdminProfilePage.js

import React, { useState, useEffect } from 'react';
import {
  Box,
  CssBaseline,
  Container,
  Typography,
  Grid2,
  Avatar,
  Button,
  Paper,
  CircularProgress,
} from '@mui/material';
import Sidebar from '../../components/navigation/MainNav';
import { useNavigate } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import axios from 'axios';
import { getUser } from '../../api/userApi';

const AdminProfilePage = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [userData, setUserData] = useState(null);
  const [error, setError] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    let isMounted = true;
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        fetchuserData(user.uid, isMounted);
      } else {
        navigate('/logout');
      }
    });

    return () => {
      isMounted = false;
      unsubscribe();
    };
  }, [navigate]);

  const fetchuserData = async (uid, isMounted) => {
    try {
      if (uid) {
       const userDataRecord = await getUser(uid);
       if (userDataRecord) {
    
        setUserData(userDataRecord)
        setIsLoading(false);
        }
      }
    } catch (error) {
      console.error('Error fetching admin data:', error);
      if (isMounted) {
        setError('Failed to fetch admin data. Please try again later.');
        setIsLoading(false);
      }
    }
  };

  if (isLoading) {
    return (
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <Sidebar />
        <Box component="main" sx={{ flexGrow: 1, p: 1 }}>
          <Container maxWidth="md" sx={{ textAlign: 'center', mt: 5 }}>
            <CircularProgress />
          </Container>
        </Box>
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <Sidebar />
        <Box component="main" sx={{ flexGrow: 1, p: 1 }}>
          <Container maxWidth="md" sx={{ textAlign: 'center', mt: 5 }}>
            <Typography color="error">{error}</Typography>
          </Container>
        </Box>
      </Box>
    );
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <Sidebar />

        <Container maxWidth="md" align="center">
        <Grid2 container sx={{ 
      height: '100vh', 
      width: { xs: '98%', md: '75%', xl: '55%' },
      display: 'flex',
      alignItems: 'center', // Center items vertically
      justifyContent: 'center', // Center items horizontally
      px: { xs: 2, md: 5 },
    }}>
    
            <Grid2 container spacing={2} alignItems="center">
              <Grid2 item>
                <Avatar
                  alt={userData.firstName}
          //        src={userData.profilePicture}
                  sx={{ width: 100, height: 100 }}
                />
              </Grid2>
              <Grid2 item>
                <Typography variant="h4">{userData.firstName}</Typography>
                <Typography variant="subtitle1">{userData.email}</Typography>
              </Grid2>
            </Grid2>

            {/* Administrative Actions */}
        
              <Typography variant="h6">Administrative Actions</Typography>
              <Grid2 container spacing={2} sx={{ mt: 2 }}>
                <Grid2 item xs={12} sm={6}>
                  <Button
                    variant="contained"
                    fullWidth
                    onClick={() => navigate('/admin/users')}
                  >
                    Manage Users
                  </Button>
                </Grid2>
                <Grid2 item xs={12} sm={6}>
                  <Button
                    variant="contained"
                    fullWidth
                    onClick={() => navigate('/admin/campaigns')}
                  >
                    Manage Campaigns
                  </Button>
                </Grid2>
                {/* Add more buttons for other actions */}
              </Grid2>

</Grid2>
        </Container>
      </Box>

  );
};

export default AdminProfilePage;
