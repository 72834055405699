import React, { useState, useEffect } from 'react';
import Sidebar from '../../components/navigation/MainNav';
import {
  Grid, Paper, List, ListItem, ListItemText, Typography, Box, TextField, Button, Divider, CssBaseline, Container, CircularProgress,
  IconButton,} from '@mui/material';
import ProfileAvatar from '../../components/navigation/profileAvatar';
import SendIcon from '@mui/icons-material/Send';
import { useLocation } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import axios from 'axios';

const MessagingComponent = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [messageSendLoading, setMessageSendLoading] = useState(false);
  const [messageContent, setMessageContent] = useState('');
  const [conversations, setConversations] = useState([]); // For storing conversations
  const [conversationMessages, setConversationMessages] = useState([]); // For storing messages within a conversation
  const location = useLocation();
  const { applicationData, creatorDetails, userId, isNewMessage, campaignDetails } = location.state || {};
  const auth = getAuth();
  const user = auth.currentUser;
  console.log(campaignDetails);
  const [newConversation, setNewConversation] = useState(false);
  const userType = localStorage.getItem('user_type'); // 'creator' or 'advertiser'


  // Poll for new messages every 5 seconds
  useEffect(() => {
    const intervalId = setInterval(() => {
      if (selectedMessage) {
       fetchMessages(selectedMessage._id);
      }
    }, 5000);

        return () => clearInterval(intervalId); // Cleanup the interval on component unmount
  }, [selectedMessage]);


  useEffect(() => {
    const intervalId = setInterval(() => {
       fetchConversations();
    }, 5000);
        return () => clearInterval(intervalId); // Cleanup the interval on component unmount
  }, []);

  // Initial fetch of conversations or new message handling
  useEffect(() => {
    if (isNewMessage) {
      setNewConversation(true);
      setSelectedMessage(null); // New message, no conversation selected yet
      fetchConversations();
    } else {
      fetchConversations();
    }
  }, [isNewMessage]);

  // Fetch conversations for the user
  const fetchConversations = async () => {
  
    try {
      if (user) {
        const idToken = await user.getIdToken();
        const response = await axios.get(`https://ostendo.nucleotto.com/api/campaign/messages/list`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${idToken}`,
          },
        });
        setConversations(response.data.conversations);
      }
    } catch (error) {
      console.error('Error fetching conversations:', error);
    } finally {

    }
  };

  // Fetch messages for the selected conversation
  const fetchMessages = async (conversationId) => {
    setNewConversation(false);
    try {
      if (user) {
        const idToken = await user.getIdToken();
        const response = await axios.post(`https://ostendo.nucleotto.com/api/campaign/messages/get`, 
            {conversationId: conversationId},
            {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${idToken}` },
        });

        console.log('Fetched messages:', response.data.messages);
      

        if (response.data && response.data.messages) {
        setConversationMessages(response.data.messages);
    } else {
        console.error('No messages found for this conversation'); // Debugging log
      }
    }
  } catch (error) {
    console.error('Error fetching messages', error);
  } finally {

  }
};  

  // Handle sending a message (new conversation or reply)
  const handleSendMessage = async () => {
    setMessageSendLoading(true);
    if (messageContent.trim()) {
      const newMessage = {
        to: userType === 'creator' ? campaignDetails.advertiserId : creatorDetails.userId, // Send to advertiser if creator, else to creator
        text: messageContent,
      };
  
      const messagePayload = {
        campaignId: applicationData.campaignId,
        campaignName: campaignDetails.campaignName,
        conversationId: selectedMessage ? selectedMessage._id : null,
        message: newMessage,
      };
  
      try {
        const auth = getAuth();
        const user = auth.currentUser;
        if (user) {
          const idToken = await user.getIdToken();
          const response = await axios.post('https://ostendo.nucleotto.com/api/campaign/messages/message', messagePayload, {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${idToken}`,
            },
          });
  
          if (!selectedMessage) {
            const newConversationId = response.data.conversationId;
            setSelectedMessage({
              id: newConversationId,
              subject: 'New Conversation',
              participants: [creatorDetails.name, 'You'],
            });
          }
  
          setMessageContent('');
          fetchMessages(selectedMessage ? selectedMessage._id : response.data.conversationId);
          setNewConversation(false);
          setMessageSendLoading(false);
        }
      } catch (error) {
        console.error('Error sending message:', error);
      }
    }
  };
  
  const handleSelectMessage = (message) => {
    setSelectedMessage(message);
    console.log('conversationID:', message._id);
    fetchMessages(message._id); // Load messages for the selected conversation
    setNewConversation(false);

  };

  return (
    <Box sx={{ display: 'flex', backgroundColor: '#f5f5f5', height: '100vh' }}>
      <CssBaseline />
      <Sidebar />
  
      {isLoading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
          <CircularProgress />
        </Box>
      ) : (
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          <Container maxWidth="xl">
            <Box sx={{ mb: 4 }}>
              <ProfileAvatar />
            </Box>
  
            <Grid container spacing={2} sx={{ height: '80vh' }}>
              {/* Left Panel: Conversation List */}
              <Grid item xs={3}>
                <Paper elevation={3} sx={{ height: '100%', overflowY: 'auto', p: 2, borderRadius: '20px' }}>
                  <Typography variant="h6" sx={{ mb: 2, fontWeight: 'bold' }}>Conversations</Typography>
                  <Divider sx={{ mb: 2 }} />
                  <List sx={{ maxHeight: 'calc(100% - 100px)', overflowY: 'auto' }}>
                    {conversations.map((conversation) => (
                      <ListItem
                        key={conversation.id}
                        button
                        onClick={() => handleSelectMessage(conversation)}
                        sx={{
                          backgroundColor: selectedMessage?.id === conversation.id ? '#eef4ff' : 'inherit',
                          mb: 1,
                          borderRadius: '8px',
                          '&:hover': { backgroundColor: '#f0f0f0' },
                          boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.1)',
                        }}
                      >
                        <ListItemText
                          primary={<Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>{conversation.subject}</Typography>}
                          secondary={<Typography variant="caption" color="textSecondary" sx={{ fontWeight: 'bold' }}>{conversation.campaignName} - {new Date(conversation.lastUpdated).toLocaleString()}</Typography>}
                        />
                          <ListItem
                          SecondaryAction
                          >
                            <IconButton edge="end" onClick={(e) => { e.stopPropagation(); /* Handle the icon action here */ }}>

                            </IconButton>
                        </ListItem>
                           </ListItem>
                      
                            
                    ))}
                  </List>
                </Paper>
              </Grid>
  
              {/* Right Panel: Message Details */}
              <Grid item xs={9}>
                <Paper elevation={3} sx={{ height: '100%', display: 'flex', flexDirection: 'column', p: 2, borderRadius: '20px' }}>
                  {newConversation ? (
                    <>
                      {/* New Message Header */}
                    {/* Message Header */ }
<Box sx={{ borderBottom: '1px solid #e0e0e0', backgroundColor: '#f9f9f9', p: 2 }}>
  <Typography variant="h6" sx={{ fontWeight: 'bold' }}>{selectedMessage.subject}</Typography>
  <Typography variant="body2" color="textSecondary">
    {userType === 'creator' ? `Advertiser: ${campaignDetails.advertiserName}` : `Creator: ${creatorDetails.email}`} - {new Date(selectedMessage.lastUpdated).toLocaleString()}
  </Typography>
</Box>

  
                      {/* New Message Content */}
                      <Box sx={{ p: 2, flexGrow: 1, overflowY: 'auto', backgroundColor: '#fff' }}>
                        <Typography variant="body2" color="textSecondary">
                          Start a new conversation with {creatorDetails.email}.
                        </Typography>
                      </Box>
  
                      {/* Reply Section for New Message */}
                      <Box sx={{ p: 2, borderTop: '1px solid #e0e0e0', backgroundColor: '#f9f9f9' }}>
                        <TextField
                          label="Message"
                          multiline
                          rows={2}
                          fullWidth
                          variant="outlined"
                          value={messageContent}
                          disabled={messageSendLoading}
                          onChange={(e) => setMessageContent(e.target.value)}
                        />
                        <Button
                          variant="contained"
                          color="primary"
                          endIcon={<SendIcon />}
                          sx={{ mt: 2 }}
                          onClick={handleSendMessage}
                          disabled={messageSendLoading}
                        >
                          Send
                        </Button>
                      </Box>
                    </>
                  ) : selectedMessage ? (
                    <>
                      {/* Message Header */}
                      <Box sx={{ borderBottom: '1px solid #e0e0e0', backgroundColor: '#f9f9f9', p: 2 }}>
                        <Typography variant="h6" sx={{ fontWeight: 'bold' }}>{selectedMessage.subject}</Typography>
                        <Typography variant="body2" color="textSecondary">
                          {selectedMessage.participants.join(', ')} - {new Date(selectedMessage.lastUpdated).toLocaleString()}
                        </Typography>
                      </Box>
  
                      {/* Message Content */}
                      <Box
                        sx={{
                          p: 2,
                          flexGrow: 1,
                          maxHeight: '600px',
                          overflowY: 'auto',
                          backgroundColor: '#fff',
                        }}
                      >
                        {conversationMessages.length > 0 ? (
                          conversationMessages.map((message, index) => (
                            <Box key={index} sx={{ mb: 3 }}>
                              <Typography variant="body2" color="textSecondary">
                                {message.from} - {new Date(message.timestamp).toLocaleString()}
                              </Typography>
                              <Typography variant="body1" sx={{ mt: 1 }}>
                                {message.text}
                              </Typography>
                              <Divider sx={{ mt: 2 }} />
                            </Box>
                          ))
                        ) : (
                          <Typography>No messages in this conversation</Typography>
                        )}
                      </Box>
  
                      {/* Reply Section */}
                      <Box sx={{ p: 2, borderTop: '1px solid #e0e0e0', backgroundColor: '#f9f9f9' }}>
                        <TextField
                          label="Reply"
                          multiline
                          rows={2}
                          fullWidth
                          variant="outlined"
                          value={messageContent}
                          onChange={(e) => setMessageContent(e.target.value)}
                          disabled={messageSendLoading}
                        />
                        <Button
                          variant="contained"
                          color="primary"
                          endIcon={<SendIcon />}
                          sx={{ mt: 2 }}
                          onClick={handleSendMessage}
                          disabled={messageSendLoading}
                        >
                          Send
                        </Button>
                      </Box>
                    </>
                  ) : (
                    <Box sx={{ p: 2 }}>
                      <Typography>Select a conversation to view its messages</Typography>
                    </Box>
                  )}
                </Paper>
              </Grid>
            </Grid>
          </Container>
        </Box>
      )}
    </Box>
  );
  
};

export default MessagingComponent;
