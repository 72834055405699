import React, { useState, useRef, useEffect } from 'react';
import {
  Box,
  Container,
  Typography,
  Button,
  Grid2,
  TextField,
  Checkbox,
  FormControlLabel,
  Tab,
  Tabs,
  FormControl,
  RadioGroup,
  Radio,
  FormLabel, MenuItem, Select


  } from '@mui/material';
import Sidebar from '../../components/navigation/MainNav';
import SettingsIcon from '@mui/icons-material/Settings';
import Avatar from '@mui/material/Avatar';
import SearchBar from '../../components/widgets/dashboard/SearchBar';
import { updateCampaign } from '../../api/campaignsApi';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import ProfileAvatar from '../../components/navigation/profileAvatar';



const EditCampaign = () => {
const location = useLocation();
const { campaignDetails, activeTab: initialActiveTab } = location.state || {};
const [ user, setUser] = useState();
const [placementData, setPlacementData] = useState([]);
const [blobUrl, setBlobUrl] = useState('');
const [isSubmitting, setIsSubmitting] = useState(false);
const user_type = localStorage.getItem('user_type');
const [imageUploadLoding, setImageUploadLoading] = useState(false);
const now = new Date();
const navigate = useNavigate();
const { jobId } = useParams();



const [campaignData, setCampaignData] = useState({
    advertiserName: campaignDetails?.advertiserName || '',
    campaignName: campaignDetails?.campaignName || '',
    jobType: {
      influencers: campaignDetails?.jobType.influencers || '',
      wordOfMouth: campaignDetails?.jobType.wordOfMouth || '',
      ugcAds: campaignDetails?.jobType.ugcAds || '',
      tvcAds: campaignDetails?.jobType.tvcAds || '',
    },
    remuneration: {
      influencerPayment: campaignDetails?.remuneration.influencerPayment || '',
      gifting: campaignDetails?.remuneration.gifting || '',
      influencerPaymentAndGifting: campaignDetails?.remuneration.influencerPaymentAndGifting || '',
    },
    campaignSummary: campaignDetails?.campaignSummary || '',
    campaignDescription: campaignDetails?.campaignDescription || '',
    websiteLink: campaignDetails?.websiteLink || '',
    startDate: campaignDetails?.startDate || '',
    endDate: campaignDetails?.endDate || '',
    contentSubmittedBy: campaignDetails?.contentSubmittedBy || '',
    productInformation: campaignDetails?.productInformation || '',
    productDelivery: campaignDetails?.productDelivery || '',
    contentExpectations: {
      influencerChannels: campaignDetails?.contentExpectations.influencerChannels || '',
      brandedCreativeAssets: campaignDetails?.contentExpectations.brandedCreativeAssets || '',
      repurposedByBrand: campaignDetails?.contentExpectations.repurposedByBrand || '',
      brandChannels: campaignDetails?.contentExpectations.brandChannels || '',
      boostedOnBrandChannels: campaignDetails?.contentExpectations.boostedOnBrandChannels || '',
      boostedOnInfluencerChannels: campaignDetails?.contentExpectations.boostedOnInfluencerChannels || '', 
    },
    useagePeriodPerAsset: campaignDetails?.usagePeriodPerAsset || '',
    boostingPeriodPerAsset:campaignDetails?.boostingPeriodPerAsset || '',
    usageDetails: campaignDetails?.usageDetails || '',
    contentPreApproval: campaignDetails?.contentPreApproval || '',
    influencerType: '',
    targetAudiences: '',
    targetAge: '',
    ugcContentPackages: '',
    ugcCreatorExperience: '',
    influencerSize: '',
    influencerGender: '',
            influencerCategoy: '',
            influencerLocation: '',
            influencerAge: '',
            audienceLocation: '',
            audienceGender: '',
            audienceAge: '',
            tvcCtvContentPackages: '',
            tvcCtvCrew: '',
            applicationCloseDate: '',
            createdBy: user,
            createdDate: now,
            contentSubmittedDate: '',
            image1: campaignDetails?.image1,
            status: campaignDetails?.status,
            placements: placementData
  });
  const [file, setFile] = useState(null); // State to store the selected file
  const [errorUploadingFile, setErrorUploadingFile] = useState('');
  const [canNotSubmitMessage, setCanNotSubmitMessage] = useState('');
  const [uploadingFileSuccessMessage, setUploadingFileSuccessMessage] = useState('');
  const [activeTab, setActiveTab] = useState(initialActiveTab || 0);

  useEffect(() => {
    const fetchPlacementsAndSetData = async () => {
      try {
        const fetchedPlacements = await getPlacements();// Fetch placements and update placementData state
        // After placements are fetched, update campaignData with placements
        setCampaignData((prevData) => ({
          ...prevData,
          placements: fetchedPlacements,
        }));
      } catch (error) {
        console.error('Error fetching placements:', error);
      }
    };
  
    fetchPlacementsAndSetData();
  }, []);

  const getPlacements = async () => {
    try {
      const auth = getAuth();
      const user = auth.currentUser;
      setUser(user);
      if (user) {
        const idToken = await user.getIdToken();
        const response = await axios.post(
          'https://ostendo.nucleotto.com/api/campaign/placement/list',
          {campaignId: jobId},
          {
            headers: {
              'Content-Type': 'application/json',
               Authorization: `Bearer ${idToken}`,
            },
          }
        );
       const fetchedPlacements = response.data.placements || [];
      setPlacementData(fetchedPlacements); // Update state

      return fetchedPlacements; // Return placements
      }
    } catch (error) {
      console.error('Error fetching campaigns:', error);
      return [];
    } 
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    console.log(name, value);
    setCampaignData({ ...campaignData, [name]: value });
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleUpload = async () => {
    if (!file) {
      console.error("No file selected.");
      return;
    }

    setImageUploadLoading(true);
  
    try {
console.log('uploading');
const response =  await axios.post('https://crowdswell.nucleotto.com/api/blob/uploadFile', file, {
  headers: {
    'Content-Type': file.type || 'application/octet-stream',
    'X-Filename': file.name,
  },
});
if (response) {
  setImageUploadLoading(false);
  setUploadingFileSuccessMessage('File successfully uploaded');
}
if (!response.data.url) {
  setErrorUploadingFile('OOPS! we encountered an error, please press upload again.');
} else {
const imageURL = response.data.url;
setBlobUrl(response.data.url);
setUploadingFileSuccessMessage('File successfully uploaded');
setCampaignData((prevData) => ({
  ...prevData,
  image1: imageURL,
  
}));

}} catch (error) {
console.error('Error uploading file:', error);
} 
};
  
  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    console.log(name);
    setCampaignData({ ...campaignData, [name]: checked });
  };

  const handleCheckboxChangeJobType = (event) => {
    const { name, checked } = event.target;
    console.log(name);
    setCampaignData((prevData) => ({
      ...prevData,
      jobType: {
        ...prevData.jobType,
        [name]: checked
      }
    }));
  };

  const handleCheckboxContentExpectations = (event) => {
    const { name, checked } = event.target;
    console.log(name);
    setCampaignData((prevData) => ({
      ...prevData,
      contentExpectations: {
        ...prevData.contentExpectations,
        [name]: checked
      }
    }));
  };

  const handleCheckboxChangeRemuneration = (event) => {
    const { name, checked } = event.target;
    setCampaignData((prevData) => ({
      ...prevData,
      remuneration: {
        ...prevData.remuneration,
        [name]: checked
      }
    }));
  };

  const handleCheckboxChangeCampaignDeliverables = (event) => {
    const { name, checked } = event.target;
    const [platform, deliverable] = name.split(".");

    setCampaignData((prevData) => ({
      ...prevData,
      campaignDeliverables: {
        ...prevData.campaignDeliverables,
        [platform]: {
          ...prevData.campaignDeliverables[platform], // Safely spread the existing platform object
          [deliverable]: checked // Update the specific deliverable
        }
      }
    }));
  };

  const addPlacement = () => {
    setCampaignData((prevData) => ({
      ...prevData,
      placements: [
        ...prevData.placements,
        {
          campaignType: '',
          platform: '',
          format: '',
          resharing: '',
          boosting: '',
          usagePeriod: '',
          exclusivityPeriod: '',
          remuneration: '',
          fee: '',
          comments: {
            campaignTypeComments: '',
            platformComments: '',
            formatComments: '',
            resharingComments: '',
            boostingComments: '',
            usagePeriodComments: '',
            exclusivityPeriodComments: '',
            remunerationComments: '',
            feeComments: ''
          }
        }
      ]
    }));
  };

  const handlePlacementChange = (index, event) => {
    const { name, value } = event.target;
    setCampaignData((prevData) => {
      const updatedPlacements = [...prevData.placements];
      updatedPlacements[index] = {
        ...updatedPlacements[index],
        [name]: value
      };
      return { ...prevData, placements: updatedPlacements };
    });
  };

  const removePlacement = (index) => {
    setCampaignData((prevData) => {
      const updatedPlacements = [...prevData.placements];
      updatedPlacements.splice(index, 1);
      return { ...prevData, placements: updatedPlacements };
    });
  };
  


  const handlePlacementCommentChange = (index, event) => {
    const { name, value } = event.target;
    setCampaignData((prevData) => {
      const updatedPlacements = [...prevData.placements];
      updatedPlacements[index].comments = {
        ...updatedPlacements[index].comments,
        [name]: value
      };
      return { ...prevData, placements: updatedPlacements };
    });
  };

  const handleSubmit = async (e) => {
   
    setIsSubmitting(true);
    e.preventDefault();
    const submitCampaign = await updateCampaign(jobId, campaignData); 
    console.log(submitCampaign);
    if (submitCampaign) {
      navigate(`/campaigns/detail/${submitCampaign}`, { state: { jobId: submitCampaign } });
    }
    setIsSubmitting(false);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <Sidebar />
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Container maxWidth="xxl">
          <Box>
          <ProfileAvatar />

            {/* Title and Button */}
            <Grid2 container alignItems="center" justifyContent="space-between" sx={{ mb: 4, mt: 4 }}>
              <Typography variant="h6" sx={{ fontWeight: 'bold' }}></Typography>
              <Button variant="contained" onClick={handleSubmit} sx={{ borderRadius: '10px' }}
               disabled={isSubmitting} // Optionally disable the button while loading
               >
                 {isSubmitting ? 'Updating...' : 'Update'}
                
              </Button>
              
            </Grid2>

        
 <Box sx={{ maxWidth: '100%', margin: 'auto', padding: 3 }}>
 <Typography variant='subtitle2'>{canNotSubmitMessage}</Typography>

      {/* Tabs Navigation */}
      <Tabs value={activeTab} onChange={handleTabChange}>
        <Tab label="Campaign" />
        <Tab label="Deliverables" />
        <Tab label="Product" />
        <Tab label="Assets" />
        <Tab label="Influencer Profile" />

      </Tabs>

      {activeTab === 0 && (
        <TabPanel>
          <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 8 }}>Campaign Summary</Typography>
          <Box maxWidth='800px'>
              <Grid2 item xs={12} md={8}>
              <TextField fullWidth label="Advertiser Name" name="advertiserName" value={campaignData.advertiserName} onChange={handleInputChange} sx={{marginBottom: 3}}/>
              </Grid2>
              <Grid2 item xs={12}md={8}>
              <TextField fullWidth label="Campaign Name" name="campaignName" value={campaignData.campaignName} onChange={handleInputChange} sx={{marginBottom: 3}}/>
              </Grid2>
              <Typography variant="h6" sx={{ fontWeight: 'bold', mt: 3, mb: 3 }}>Campaign Type</Typography>
             <Grid2 container spacing={2}>
          <Grid2 item xs={6} sm={3}>
            <FormControlLabel
              control={<Checkbox name="influencers" checked={campaignData.jobType.influencers} onChange={handleCheckboxChangeJobType} />}
              label="Influencers"
            />
          </Grid2>
          <Grid2 item xs={6} sm={3}>
            <FormControlLabel
              control={<Checkbox name="wordOfMouth" checked={campaignData.jobType.wordOfMouth} onChange={handleCheckboxChangeJobType} />}
              label="Word of Mouth"
            />
          </Grid2>
          <Grid2 item xs={6} sm={3}>
            <FormControlLabel
              control={<Checkbox name="ugcAds" checked={campaignData.jobType.ugcAds} onChange={handleCheckboxChangeJobType} />}
              label="UGC Ads"
            />
          </Grid2>
          <Grid2 item xs={6} sm={3}>
            <FormControlLabel
              control={<Checkbox name="tvcAds" checked={campaignData.jobType.tvcAds} onChange={handleCheckboxChangeJobType} />}
              label="TVC / CTV Ads"
            />
          </Grid2>
        </Grid2>
        <Grid2 item xs={12} md={8}>
              <TextField fullWidth label="Campaign Summary" name="campaignSummary" multiline rows={3} inputProps={{ maxLength: 150 }}
          helperText={`${campaignData.campaignSummary.length}/150`} value={campaignData.campaignSummary} onChange={handleInputChange} sx={{marginBottom: 3, marginTop: 3}}/>
              </Grid2>
              <Grid2 item xs={12} md={8}>
              <TextField fullWidth label="Campaign Description" name="campaignDescription" multiline rows={6} value={campaignData.campaignDescription} onChange={handleInputChange} sx={{marginBottom: 3}}/>
              </Grid2>
              <Grid2 item xs={12} md={8}>
              <Typography variant="h6" sx={{ fontWeight: 'bold', mt: 3, mb: 3 }}>Campaign Timing</Typography>
              <TextField
                type="date"
                label="Start Date"
                name="startDate"
                value={campaignData.startDate}
                onChange={handleInputChange}
                InputLabelProps={{ shrink: true }}
                sx={{ mb: 2, mr: 3 }}
                 /> <TextField
        type="date"
        label="End Date"
        name="endDate"
        value={campaignData.endDate}
        onChange={handleInputChange}
        InputLabelProps={{ shrink: true }}
        sx={{ mb: 2, mr: 3 }}
/>
<TextField
                type="date"
                label="Content Submitted By"
                name="contentSubmittedBy"
                value={campaignData.contentSubmittedDate}
                onChange={handleInputChange}
                InputLabelProps={{ shrink: true }}
                sx={{ mb: 2, mr: 3 }}
        />
        <TextField
                type="date"
                label="Application Close Date"
                name="applicationCloseDate"
                value={campaignData.applicationCloseDate}
                onChange={handleInputChange}
                InputLabelProps={{ shrink: true }}
                sx={{ mb: 2, mr: 3 }}
        />
              </Grid2>
              <Grid2 item xs={12} md={8} marginTop={4}>
              <Box>
      <Typography variant="h6" marginBottom={4} sx={{fontWeight: 'bold'}}>Upload a campaign image</Typography>

      <input type="file" onChange={handleFileChange} />
      
      <Button variant="contained" onClick={handleUpload} disabled={!file}
               >
                 {imageUploadLoding ? 'Uploading...' : 'Upload'}
                
              </Button>

        <Typography variant='subtitle2'>{errorUploadingFile}{uploadingFileSuccessMessage}</Typography>

     
    </Box>
              </Grid2>

          </Box>
        </TabPanel>
      )}

{activeTab === 1 && (

<TabPanel>
  <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>Deliverables</Typography>
{/* List of Placements */}
{campaignData.placements.map((placement, index) => (
      <Box key={index} sx={{ border: '1px solid #ccc', padding: 2, marginBottom: 2 }}>
        <Typography variant="subtitle1" sx={{ mb: 2 }}>Placement {index + 1}</Typography>

  <Box maxWidth="100%" sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
    {/* Column for Labels */}
    <Grid2 container direction="column" xs={2} spacing={3} marginRight={14}>
      <Grid2 item height={56}>
        <Typography variant="subtitle1">Campaign Type</Typography>
      </Grid2>
      <Grid2 item height={56}>
        <Typography variant="subtitle1">Platform</Typography>
      </Grid2>
      <Grid2 item height={56}>
        <Typography variant="subtitle1">Format</Typography>
      </Grid2>
      <Grid2 item height={56}>
        <Typography variant="subtitle1">Resharing</Typography>
      </Grid2>
      <Grid2 item height={56}>
        <Typography variant="subtitle1">Boosting</Typography>
      </Grid2>
      <Grid2 item height={56}>
        <Typography variant="subtitle1">Usage Period</Typography>
      </Grid2>
      <Grid2 item height={56}>
        <Typography variant="subtitle1">Exclusivity Period</Typography>
      </Grid2>
      <Grid2 item height={56}>
        <Typography variant="subtitle1">Remuneration</Typography>
      </Grid2>
      <Grid2 item height={56}>
        <Typography variant="subtitle1">Fee</Typography>
      </Grid2>
    </Grid2>

    {/* Column for Inputs */}
    <Grid2 container direction="column" xs={4} spacing={3}>
      <Grid2 item>
        <FormControl fullWidth>
          <Select
            name="campaignType"
            value={placement.campaignType || ''}
            onChange={(e) => handlePlacementChange(index, e)}
            displayEmpty
          >
            <MenuItem value="">
              <em>Select Campaign Type</em>
            </MenuItem>
            <MenuItem value="Influencers">Influencers</MenuItem>
            <MenuItem value="Brand">Brand</MenuItem>
            <MenuItem value="UGC Ads">UGC Ads</MenuItem>
            <MenuItem value="TVC Ads">TVC Ads</MenuItem>
          </Select>
        </FormControl>
      </Grid2>
      <Grid2 item>
        <FormControl fullWidth>
          <Select
            name="platform"
            value={placement.platform || ''}
            onChange={(e) => handlePlacementChange(index, e)}
            displayEmpty
          >
            <MenuItem value="">
              <em>Select Platform</em>
            </MenuItem>
            <MenuItem value="Influencers">Facebook</MenuItem>
            <MenuItem value="Brand">Instagram</MenuItem>
            <MenuItem value="UGC Ads">TikTok</MenuItem>
            <MenuItem value="TVC Ads">Youtube</MenuItem>
          </Select>
        </FormControl>
      </Grid2>
      <Grid2 item>
        <FormControl fullWidth>
          <Select
            name="format"
            value={placement.format || ''}
            onChange={(e) => handlePlacementChange(index, e)}
            displayEmpty
          >
            <MenuItem value="">
              <em>Select Format</em>
            </MenuItem>
            <MenuItem value="Reel">Reel</MenuItem>
            <MenuItem value="Image">Image</MenuItem>
            <MenuItem value="Video">Video</MenuItem>
          </Select>
        </FormControl>
      </Grid2>
      <Grid2 item>
        <FormControl fullWidth>
          <Select
            name="resharing"
            value={placement.resharing || ''}
            onChange={(e) => handlePlacementChange(index, e)}
            displayEmpty
          >
            <MenuItem value="">
              <em>Select Resharing</em>
            </MenuItem>
            <MenuItem value="Yes">Yes</MenuItem>
            <MenuItem value="No">No</MenuItem>
          </Select>
        </FormControl>
      </Grid2>
      <Grid2 item>
        <FormControl fullWidth>
          <Select
            name="boosting"
            value={placement.boosting || ''}
            onChange={(e) => handlePlacementChange(index, e)}
                        displayEmpty
          >
            <MenuItem value="">
              <em>Select Boosting Option</em>
            </MenuItem>
            <MenuItem value="Influencers">Influencers</MenuItem>
            <MenuItem value="Brand">Brand</MenuItem>
          </Select>
        </FormControl>
      </Grid2>
      <Grid2 item>
        <FormControl fullWidth>
          <Select
            name="usagePeriod"
            value={placement.usagePeriod || ''}
            onChange={(e) => handlePlacementChange(index, e)}
            displayEmpty
          >
            <MenuItem value="">
              <em>Select Usage Period</em>
            </MenuItem>
            <MenuItem value="1 Month">1 Month</MenuItem>
            <MenuItem value="6 Months">6 Months</MenuItem>
            <MenuItem value="12 Months">12 Months</MenuItem>
          </Select>
        </FormControl>
      </Grid2>
      <Grid2 item>
        <FormControl fullWidth>
          <Select
            name="exclusivityPeriod"
            value={placement.exclusivityPeriod || ''}
            onChange={(e) => handlePlacementChange(index, e)}
            displayEmpty
          >
            <MenuItem value="">
              <em>Select Exclusivity Period</em>
            </MenuItem>
            <MenuItem value="1 Month">1 Month</MenuItem>
            <MenuItem value="6 Months">6 Months</MenuItem>
            <MenuItem value="12 Months">12 Months</MenuItem>
          </Select>
        </FormControl>
      </Grid2>
      <Grid2 item>
        <FormControl fullWidth>
          <Select
            name="remuneration"
            value={placement.remuneration || ''}
            onChange={(e) => handlePlacementChange(index, e)}
            displayEmpty
          >
            <MenuItem value="">
              <em>Select Remuneration Type</em>
            </MenuItem>
            <MenuItem value="Product">Product</MenuItem>
            <MenuItem value="Payment">Payment</MenuItem>
            <MenuItem value="Product & Payment">Product & Payment</MenuItem>
          </Select>
        </FormControl>
      </Grid2>
      <Grid2 item>
        <TextField
          fullWidth
          label="Fee"
          name="fee"
          value={placement.fee || ''}
          onChange={(e) => handlePlacementChange(index, e)}        />
      </Grid2>
    </Grid2>

    <Grid2 container direction="column" xs={12} spacing={3}>
    <Grid2 item>
    <FormControl fullWidth>
      <TextField
            name="campaignTypeComments"
            label="Campaign Type: Comments"
            value={placement.comments.campaignTypeComments || '' }
            onChange={(e) => handlePlacementCommentChange(index, e)}
            sx={{ minWidth: 400, width: '100%' }} 
         >      
          </TextField>
          </FormControl>
      </Grid2>
      <Grid2 item>
    <FormControl fullWidth>
      <TextField
            name="platformComments"
            label="Platform: Comments"
            value={placement.comments.platformComments || '' }
            onChange={(e) => handlePlacementCommentChange(index, e)}
            sx={{ minWidth: 400, width: '100%' }} 
         >      
          </TextField>
          </FormControl>
      </Grid2>
      
      <Grid2 item>
        <FormControl fullWidth>
          <TextField
            name="formatComments"
            label="Format: Comments"
            value={placement.comments.formatComments || '' }
            onChange={(e) => handlePlacementCommentChange(index, e)}
            sx={{ minWidth: 400, width: '100%' }} 
         >      
          </TextField>
        </FormControl>
      </Grid2>
      <Grid2 item>
        <FormControl fullWidth>
        <TextField
            name="resharingComments"
            label="Resharing: Comments"
            value={placement.comments.resharingComments || '' }
            onChange={(e) => handlePlacementCommentChange(index, e)}
            sx={{ minWidth: 400, width: '100%' }} 
         >      
          </TextField>
        </FormControl>
      </Grid2>
      <Grid2 item>
        <FormControl fullWidth>
        <TextField
            name="boostingComments"
            label="Boosting: Comments"
            value={placement.comments.boostingComments || '' }
            onChange={(e) => handlePlacementCommentChange(index, e)}
            sx={{ minWidth: 400, width: '100%' }} 
         >      
          </TextField>
        </FormControl>
      </Grid2>
      <Grid2 item>
        <FormControl fullWidth>
        <TextField
            name="usagePeriodComments"
            label="Usage Period: Comments"
            value={placement.comments.usagePeriodComments || '' }
            onChange={(e) => handlePlacementCommentChange(index, e)}
            sx={{ minWidth: 400, width: '100%' }} 
         >      
          </TextField>
        </FormControl>
      </Grid2>
      <Grid2 item>
        <FormControl fullWidth>
        <TextField
            name="exclusivityPeriodComments"
            label="Exlusivity Period: Comments"
            value={placement.comments.exclusivityPeriodComments || '' }
            onChange={(e) => handlePlacementCommentChange(index, e)}
            sx={{ minWidth: 400, width: '100%' }} 
         >      
          </TextField>
        </FormControl>
      </Grid2>
      <Grid2 item>
        <FormControl fullWidth>
        <TextField
            name="remunerationComments"
            label="Remuneration: Comments"
            value={placement.comments.remunerationComments || '' }
            onChange={(e) => handlePlacementCommentChange(index, e)}
            sx={{ minWidth: 400, width: '100%' }} 
         >      
          </TextField>
        </FormControl>
      </Grid2>
      <Grid2 item>
        <FormControl fullWidth>
        <TextField
            name="feeComments"
            label="Fee: Comments"
            value={placement.comments.feeComments || '' }
            onChange={(e) => handlePlacementCommentChange(index, e)}
            sx={{ minWidth: 400, width: '100%' }} 
         >      
          </TextField>
        </FormControl>
      </Grid2>
   
    </Grid2>
  </Box>
      {/* Remove Placement Button */}
      <Button variant="outlined" color="error" onClick={() => removePlacement(index)} sx={{ mt: 2 }}>
          Remove Placement
        </Button>
      </Box>
    ))}

    {/* Add Placement Button */}
    <Button variant="contained" onClick={addPlacement}>
      Add Placement
    </Button>
</TabPanel>

      )}
  
{activeTab === 2 && (
        <TabPanel>
      
          <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>Product</Typography>
          <Box maxWidth='800px'>
          <Grid2 item xs={12} md={8}>
              <TextField fullWidth label="Product Information" name="productInformation" multiline rows={4} value={campaignData.productInformation} onChange={handleInputChange} sx={{marginBottom: 3}}/>
              </Grid2>

          
          <Grid2 item xs={6} sm={3}>
          <FormControl>
          <Typography variant="h6" sx={{ fontWeight: 'bold', mt: 3, mb: 3 }}>Product Delivery</Typography>
          <RadioGroup
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
      >
        <FormControlLabel value="collectProduct" control={<Radio />} label="Creator will need to collect from a specific location" />
        <FormControlLabel value="deliverProduct" control={<Radio />} label="Creator will be shipped the product" />

      </RadioGroup>
    </FormControl>
         </Grid2>


              </Box>
        </TabPanel>
      )}

{activeTab === 3 && (
        <TabPanel>
    
          <Box maxWidth='800px'>
          <Typography variant="h6" sx={{ fontWeight: 'bold', mt: 3, mb: 3 }}>Content Expectations</Typography>
          <Grid2 item xs={6} sm={3}>
            <FormControlLabel
              control={<Checkbox name="influencerChannels" checked={campaignData.contentExpectations.influencerChannels} onChange={handleCheckboxContentExpectations} />}
              label="Content will be published on Influencer channels"
            />
          </Grid2>
          <Grid2 item xs={6} sm={3}>
            <FormControlLabel
              control={<Checkbox name="brandedCreativeAssets" checked={campaignData.contentExpectations.brandedCreativeAssets} onChange={handleCheckboxContentExpectations} />}
              label="Content will only be used as branded creative assets"
            />
          </Grid2>
          <Grid2 item xs={6} sm={3}>
            <FormControlLabel
              control={<Checkbox name="repurposedByBrand" checked={campaignData.contentExpectations.repurposedByBrand} onChange={handleCheckboxContentExpectations} />}
              label="Content will be repurposed by the Brands"
            />
          </Grid2>
          <Grid2 item xs={6} sm={3}>
            <FormControlLabel
              control={<Checkbox name="brandChannels" checked={campaignData.contentExpectations.brandChannels} onChange={handleCheckboxContentExpectations} />}
              label="Content will be published on Brand Channels"
            />
          </Grid2>
          <Grid2 item xs={6} sm={3}>
            <FormControlLabel
              control={<Checkbox name="boostedOnBrandChannels" checked={campaignData.contentExpectations.boostedOnBrandChannels} onChange={handleCheckboxContentExpectations} />}
              label="Content will be boosted as ads from the Brand Channels"
            />
          </Grid2>
          <Grid2 item xs={6} sm={3}>
            <FormControlLabel
              control={<Checkbox name="boostedOnInfluencerChannels" checked={campaignData.contentExpectations.boostedOnInfluencerChannels} onChange={handleCheckboxContentExpectations} />}
              label="Content will be boosted as ads from the Influencers channels"
            />
          </Grid2>
          <Grid2 item xs={12} md={8}>
              <TextField fullWidth label="Usage Period Per Asset" name="useagePeriodPerAsset" value={campaignData.useagePeriodPerAsset} onChange={handleInputChange} sx={{marginBottom: 3, marginTop: 3}}/>
              </Grid2>
              <Grid2 item xs={12} md={8}>
              <TextField fullWidth label="Boosting Period Per Asset" name="boostingPeriodPerAsset" value={campaignData.boostingPeriodPerAsset} onChange={handleInputChange} sx={{marginBottom: 3}}/>
              </Grid2>
              <Grid2 item xs={12} md={8}>
              <TextField fullWidth label="Usage Details" name="usageDetails" value={campaignData.usageDetails} onChange={handleInputChange} sx={{marginBottom: 3}}/>
              </Grid2>
              <Grid2 item xs={6} sm={3}>
            <FormControlLabel
              control={<Checkbox name="contentPreApproval" checked={campaignData.contentPreApproval} onChange={handleCheckboxChange} />}
              label="Content pre-approval before going live"
            />
          </Grid2>
              


              </Box>
        </TabPanel>
      )}


{activeTab === 7 && (
        <TabPanel>
          <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>Influencer Profile</Typography>

       
        <Grid2 container spacing={2}>
         
      
        </Grid2>
        </TabPanel>
      )}




  </Box>                  
              
          </Box>
          <Box align="right" marginTop='20px'>
          <Button variant="contained" onClick={handleSubmit} sx={{ borderRadius: '10px' }}
               disabled={isSubmitting} // Optionally disable the button while loading
               >
                 {isSubmitting ? 'Updating...' : 'Update'}
                
              </Button>
              <Typography variant='subtitle2'>{canNotSubmitMessage}</Typography>
          </Box>
        </Container>
      </Box>
    </Box>
  );
};

const TabPanel = (props) => {
  const { children } = props;
  return (
    <Box sx={{ paddingTop: 3 }}>
      {children}
    </Box>
  );
};

export default EditCampaign;
