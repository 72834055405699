import React, { useState } from 'react';
import { 
  Box, 
  Button, 
  TextField, 
  Typography, 
  Checkbox, 
  FormControlLabel, 
  Grid2, 
  Divider, 
  Container,
 } from '@mui/material';
import { signInWithEmailAndPassword, FacebookAuthProvider, signInWithPopup } from 'firebase/auth';
import { auth, googleProvider } from '../../config/firebase'; // Import Firebase configuration
import { useNavigate } from 'react-router-dom';
import { getUser } from '../../api/userApi';
import SocialSignInButton from '../../components/SocialSignInButton';
import GoogleIcon from '@mui/icons-material/Google';
import FacebookIcon from '@mui/icons-material/Facebook';

const LoginPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [token, setToken] = useState('');
  const [signInLoading, setSignInLoading] = useState(false);
  const facebookProvider = new FacebookAuthProvider();
  const navigate = useNavigate();
  const [rememberMe, setRememberMe] = useState(false);

  
  // Handle email/password login
  const handleSignIn = async (e) => {
    e.preventDefault();
    if (!email || !password) {
      setError('Please fill in all fields.');
      return;
    }
    setSignInLoading(true);
    try {
        const signIn = await signInWithEmailAndPassword(auth, email, password);
      const userId = signIn.user.uid;
      if (rememberMe) {
        localStorage.setItem('userId', userId);
        localStorage.setItem('email', email);
      } else {
        sessionStorage.setItem('userId', userId);
        sessionStorage.setItem('email', email);
      }

      const userRecord = await getUser(userId);
      const user_type = userRecord.result.user_type;
      localStorage.setItem('user_type', user_type);
      if (user_type === 'admin') {
      navigate('/campaigns');
      } else if (user_type === 'creator') {
        navigate('/creators/dashboard')
      }
    } catch (err) {
      console.error(err);
      setSignInLoading(false);
      if (err.code === 'auth/wrong-password') {
        setError('Incorrect password. Please try again.');
      } else if (err.code === 'auth/user-not-found') {
        setError('No account found with this email.');
      } else {
        setError('An error occurred. Please try again.');
      }
    }
  };

  // Handle Google sign-in
  const handleGoogleSignIn = async (e) => {
    e.preventDefault();
    setSignInLoading(true);
    try {
      const signIn = await signInWithPopup(auth, googleProvider);
      const userId = signIn.user.uid;
      console.dir(signIn, { depth: null });
      localStorage.setItem('userId', userId)
      localStorage.setItem('email', signIn.user.email)
      localStorage.setItem('display_name', signIn.user.displayName)
      const userRecord = await getUser(userId);
      const user_type = userRecord.result.user_type;
      localStorage.setItem('user_type', user_type);
      if (user_type === 'admin') {
      navigate('/campaigns');
      } else if (user_type === 'creator') {
        navigate('/creators/dashboard')
      }
    } catch (err) {
      console.error(err);
      setSignInLoading(false);
      if (err.code === 'auth/wrong-password') {
        setError('Incorrect password. Please try again.');
      } else if (err.code === 'auth/user-not-found') {
        setError('No account found with this email.');
      } else {
        setError('An error occurred. Please try again.');
      }
    }
  };

  const handleFacebookSignIn = async (e) => {
    setSignInLoading(true);
    e.preventDefault();
    try {
      const signIn = await signInWithPopup(auth, facebookProvider);
      const userId = signIn.user.uid;
      console.dir(signIn, { depth: null });
      localStorage.setItem('userId', userId)
      localStorage.setItem('email', signIn.user.email)
      localStorage.setItem('display_name', signIn.user.displayName)
 
      
      const userRecord = await getUser(userId);
      const user_type = userRecord.result.user_type;
      localStorage.setItem('user_type', user_type);
      if (user_type === 'admin') {
      navigate('/campaigns');
      } else if (user_type === 'creator') {
        navigate('/creators/dashboard')
      }
    } catch (err) {
      console.error(err);
      setSignInLoading(false);
      if (err.code === 'auth/wrong-password') {
        setError('Incorrect password. Please try again.');
      } else if (err.code === 'auth/user-not-found') {
        setError('No account found with this email.');
      } else {
        setError('An error occurred. Please try again.');
      }
    }
  };

  

  const handleSignUpClick = async (e) => {
     try {
      navigate('/register');
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Container align="center">
     
       <Grid2 container sx={{ 
      height: '100vh', 
      width: { xs: '98%', md: '75%', xl: '55%' },
      display: 'flex',
      alignItems: 'center', // Center items vertically
      justifyContent: 'center', // Center items horizontally
      px: { xs: 2, md: 5 },
    }}>
      
      <Grid2 item align='center' xs={12} md={6} sx={{ p: 5, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
    
       
        <Box sx={{ mb: 5 }}>
          
        <Typography variant="h3" sx={{ fontWeight: 'bold', mb: 3 }}>CrowdSwell</Typography>
        
          <Typography variant="body1" sx={{ mt: 2 }}>
            Developer Version 2.1
          </Typography>
        </Box>
        
        {/* Social Sign-In Buttons */}
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
          
       <SocialSignInButton 
        onClick={handleGoogleSignIn}
        icon={<GoogleIcon />}
        text="Sign in with Google"/>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
        <SocialSignInButton 
        onClick={handleFacebookSignIn}
        icon={<FacebookIcon />}
        text="Sign in with Facebook"/>
        </Box>

        <Divider sx={{ my: 2 }}>OR</Divider>

        {/* Email/Password Sign-In */}
        <Box>
        <TextField
  fullWidth
  label="Email"
  type="email"
  variant="outlined"
  margin="normal"
  value={email}
  onChange={(e) => setEmail(e.target.value)}
  slotProps={{
    input: { 'aria-label': 'Email Address' },
  }}
  sx={{
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'black',
        borderRadius: '8px',
      },
      '&:hover fieldset': {
        borderColor: '#333',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'black',
      },
    },
    '& .MuiInputBase-input': {
      color: 'black',
    },
    '& .MuiInputLabel-root': {
      color: 'black',
    },
    '& .MuiInputLabel-root.Mui-focused': {
      color: 'black',
    },
  }}
/>
          <TextField
            fullWidth
            label="Password"
            type="password"
            variant="outlined"
            margin="normal"
  value={password}
  onChange={(e) => setPassword(e.target.value)}
  slotProps={{
    input: { 'aria-label': 'Password' },
  }}
  sx={{
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'black',
        borderRadius: '8px',
      },
      '&:hover fieldset': {
        borderColor: '#333',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'black',
      },
    },
    '& .MuiInputBase-input': {
      color: 'black',
    },
    '& .MuiInputLabel-root': {
      color: 'black',
    },
    '& .MuiInputLabel-root.Mui-focused': {
      color: 'black',
    },
  }}
/>

          {error && (
            <Typography color="error" sx={{ mt: 2 }}>
              {error}
            </Typography>
          )}

          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 2 }}>
          <FormControlLabel
  control={<Checkbox checked={rememberMe} onChange={(e) => setRememberMe(e.target.checked)} />}
  label="Remember Me"
/>            <Button href="#" color="primary">
              Forget Password?
            </Button>
          </Box>

          <Button
  variant="contained"
  fullWidth
  sx={{
    mt: 3,
    py: 1.5,
    backgroundColor: 'black',
    color: 'white',
    borderRadius: '8px',
    '&:hover': {
      backgroundColor: '#333', // Darker shade on hover
    },
  }}
  onClick={handleSignIn}
>
  Sign in
</Button>
        </Box>

        <Typography variant="body2" sx={{ mt: 2 }}>
          Don’t have an account? <Button onClick={handleSignUpClick}>Sign Up</Button>
        </Typography>
      </Grid2>
    </Grid2> 

      </Container>

  )
};

export default LoginPage;
