import React from 'react';
import { Box, CssBaseline, Container, Typography, Button, Grid2, Avatar } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const CreateUserAdmin = () => {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate(`/users/create`);
      };

  return (
    
       <Button variant="contained" onClick={handleClick} sx={{borderRadius: '10px', backgroundColor: '#11ba82', height: '56px', mt: '10px'}}>Create User</Button>
       
        
  );
};

export default CreateUserAdmin;
