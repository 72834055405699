import axios from 'axios';
import { getAuth } from 'firebase/auth';

const BASE_URL = 'https://ostendo.nucleotto.com/api/profile/brand'; // move to env

export const createBrand = async (brandData, idToken) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/create`,
      brandData,
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${idToken}`
        }
      }
    );

    return response.data;
  } catch (error) {
    console.error('Error creating brand', error);
    throw error; // Handle errors appropriately
  }
};

export const getAllBrands = async () => {
  try {
    const response = await axios.get(
      `${BASE_URL}/list`,
   
      {
        headers: {
          'Content-Type': 'application/json',
 //         'Authorization': `Bearer ${idToken}`
        }
      }
    );

    return response.data.result;
  } catch (error) {
    console.error('Error retrieving brand', error);
    throw error; // Handle errors appropriately
  }
};

export const getBrand = async (profileId) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/list?profileId=${profileId}`,
   
      {
        headers: {
          'Content-Type': 'application/json',
 //         'Authorization': `Bearer ${idToken}`
        }
      }
    );

    return response.data;
  } catch (error) {
    console.error('Error retrieving brand', error);
    throw error; // Handle errors appropriately
  }
};