import axios from 'axios';
import { getAuth } from 'firebase/auth';

const BASE_URL = 'https://ostendo.nucleotto.com/api/user'; // move to env

export const getUser = async (userId) => {
    try {
            const response = await axios.get(
            `${BASE_URL}/getUser?userId=${userId}`, 
              {
              headers: {
               
               'Content-Type': 'application/json'
            //    'Authorization': `Bearer ${accessToken}`
              }
            }
          );

          return response.data;
        } catch (error) {
          console.error('Error creating campaigns', error);
          throw error; // Handle errors appropriately
        }
      };

      export const getAllUsers = async () => {
        try {
                const response = await axios.get(
                `${BASE_URL}/getAllUsers`, 
                  {
                  headers: {
                   
                   'Content-Type': 'application/json'
                //    'Authorization': `Bearer ${accessToken}`
                  }
                }
              );
    
              return response.data.result; // Access the result array
            } catch (error) {
              console.error('Error fetching users', error);
              throw error; // Handle errors appropriately
            }
          };
    

      export const registerUser = async (user, type) => {
        try {
          const auth = getAuth();
          const currentUser = auth.currentUser;
          if (currentUser) {
            const accessToken = await currentUser.getIdToken();
            const response = await axios.post(
              `${BASE_URL}/register`,
              { user, type },
              {
                headers: {
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${accessToken}`
                }
              }
            );
      
            return response.data;
          } else {
            throw new Error('User not authenticated');
          }
        } catch (error) {
          console.error('Error creating user', error);
          throw error; // Handle errors appropriately
        }
      };

          export const updateUser = async (user, updateData) => {
            try {
                    const response = await axios.post(
                    `${BASE_URL}/updateUser`, 
                    {
                      user, updateData
                    },
                      {
                      headers: {
                       
                       'Content-Type': 'application/json'
                    //    'Authorization': `Bearer ${accessToken}`
                      }
                    }
                  );
        
                  return response.data;
                } catch (error) {
                  console.error('Error updating user', error);
                  throw error; // Handle errors appropriately
                }
              };
