import React, { useState, useEffect } from 'react';
import {
  Box,
  Container,
  Typography,
  Button,
  Grid2,
  TextField,
  Paper,
  Tabs,
  Tab,
  CircularProgress,
  RadioGroup,
  FormControlLabel,
  Radio
} from '@mui/material';
import Sidebar from '../../components/navigation/MainNav';
import ProfileAvatar from '../../components/navigation/profileAvatar';
import { useNavigate } from 'react-router-dom';
import { getAuth, createUserWithEmailAndPassword } from 'firebase/auth'; // Import createUserWithEmailAndPassword
import axios from 'axios';
import { registerUser } from '../../api/userApi';
import { getAllBrands } from '../../api/brandApi'; // Import getAllBrands

const CreateUserPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [userData, setUserData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    mobile: '',
    password: '',
    profile_image: '',
    user_type: '',
    brandId: '' // Add brandId field
  });
  const [file, setFile] = useState(null);
  const [blobUrl, setBlobUrl] = useState('');
  const [error, setError] = useState('');
  const [uploadingFileSuccessMessage, setUploadingFileSuccessMessage] = useState('');
  const [errorUploadingFile, setErrorUploadingFile] = useState('');
  const [activeTab, setActiveTab] = useState(0);
  const [brands, setBrands] = useState([]); // Add state for brands
  const navigate = useNavigate();

  useEffect(() => {
    const fetchBrands = async () => {
      try {
        const brandsList = await getAllBrands();
        setBrands(brandsList);
      } catch (error) {
        console.error('Error fetching brands:', error);
      }
    };

    fetchBrands();
  }, []);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setUserData({ ...userData, [name]: value });
  };

  const handleUserTypeChange = (event) => {
    const userType = event.target.value;
    setUserData({ ...userData, user_type: userType, brandId: userType === 'admin' ? '' : userData.brandId });
  };

  const handleBrandChange = (event) => {
    setUserData({ ...userData, brandId: event.target.value });
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleUpload = async () => {
    if (!file) {
      console.error("No file selected.");
      return;
    }

    try {
      const response = await axios.post('https://crowdswell.nucleotto.com/api/blob/uploadFile', file, {
        headers: {
          'Content-Type': file.type || 'application/octet-stream',
          'X-Filename': file.name,
        },
      });
      if (response) {
        setUploadingFileSuccessMessage('File successfully uploaded');
      }
      if (!response.data.url) {
        setErrorUploadingFile('OOPS! we encountered an error, please press upload again.');
      } else {
        const imageURL = response.data.url;
        setBlobUrl(response.data.url);
        setUploadingFileSuccessMessage('File successfully uploaded');
        setUserData((prevData) => ({
          ...prevData,
          profile_image: imageURL,
        }));
      }
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    setError('');

    const missingFields = [];
    if (!userData.firstName) missingFields.push('First Name');
    if (!userData.lastName) missingFields.push('Last Name');
    if (!userData.email) missingFields.push('Email');
    if (!userData.mobile) missingFields.push('Mobile');
    if (!userData.password) missingFields.push('Password');
    if (!userData.user_type) missingFields.push('User Type');
    if ((userData.user_type === 'brandAdmin' || userData.user_type === 'brandUser') && !userData.brandId) {
      missingFields.push('Brand');
    }

    if (missingFields.length > 0) {
      setError(`Please fill in the following fields: ${missingFields.join(', ')}`);
      setIsLoading(false);
      return;
    }

    try {
      const auth = getAuth();
      const currentUser = auth.currentUser;
      if (!currentUser) {
        throw new Error('User not authenticated');
      }
      const idToken = await currentUser.getIdToken(); // Get idToken of the current user

      const userCredential = await createUserWithEmailAndPassword(auth, userData.email, userData.password);
      const firebaseUser = userCredential.user;

      const updatedUserData = {
        ...userData,
        userId: firebaseUser.uid // Add Firebase userId to userData
      };

      await registerUser(updatedUserData, idToken); // Use the old user's idToken
      navigate('/users');
    } catch (error) {
      console.error('Error creating user:', error);
      setError('Failed to create user. Please try again later.');
      setIsLoading(false);
    }
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <Sidebar />
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Container maxWidth="xxl">
          <Box>
            <ProfileAvatar />
            <Typography variant="h5" sx={{ my: 4, mt: 6, fontWeight: 'bold' }} align="left">
              Create New User
            </Typography>
              <Tabs value={activeTab} onChange={handleTabChange}>
                <Tab label="User Details" />
                <Tab label="Upload Image" />
                <Tab label="User Type" />
              </Tabs>
              {activeTab === 0 && (
                <TabPanel>
                  <form onSubmit={handleSubmit}>
                    <Box maxWidth='800px'>
                      <Grid2 item xs={12} md={8}>
                        <TextField
                          label="First Name"
                          variant="outlined"
                          fullWidth
                          name="firstName"
                          value={userData.firstName}
                          onChange={handleInputChange}
                          required
                          sx={{marginBottom: 3}}
                        />
                      </Grid2>
                      <Grid2 item xs={12} md={8}>
                        <TextField
                          label="Last Name"
                          variant="outlined"
                          fullWidth
                          name="lastName"
                          value={userData.lastName}
                          onChange={handleInputChange}
                          required
                          sx={{marginBottom: 3}}
                        />
                      </Grid2>
                      <Grid2 item xs={12}>
                        <TextField
                          label="Email"
                          variant="outlined"
                          fullWidth
                          name="email"
                          value={userData.email}
                          onChange={handleInputChange}
                          required
                          sx={{marginBottom: 3}}
                        />
                      </Grid2>
                      <Grid2 item xs={12}>
                        <TextField
                          label="Mobile"
                          variant="outlined"
                          fullWidth
                          name="mobile"
                          value={userData.mobile}
                          onChange={handleInputChange}
                          required
                          sx={{marginBottom: 3}}
                        />
                      </Grid2>
                      <Grid2 item xs={12}>
                        <TextField
                          label="Password"
                          variant="outlined"
                          fullWidth
                          type="password"
                          name="password"
                          value={userData.password}
                          onChange={handleInputChange}
                          required
                          sx={{marginBottom: 3}}
                        />
                      </Grid2>
                      {error && (
                        <Grid2 item xs={12}>
                          <Typography color="error">{error}</Typography>
                        </Grid2>
                      )}
                      <Grid2 item xs={12}>
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          fullWidth
                          disabled={isLoading}
                        >
                          {isLoading ? <CircularProgress size={24} /> : 'Create User'}
                        </Button>
                      </Grid2></Box>
                  </form>
                </TabPanel>
              )}
              {activeTab === 1 && (
                <TabPanel>
                 <Box maxWidth='800px'>
                    <Typography variant="h6" marginBottom={4} sx={{ fontWeight: 'bold' }}>
                      Upload a profile image
                    </Typography>
                    <input type="file" onChange={handleFileChange} />
                    <Button variant="contained" onClick={handleUpload} disabled={!file}>
                      Upload
                    </Button>
                    <Typography variant="subtitle2">{errorUploadingFile}{uploadingFileSuccessMessage}</Typography>
                  </Box>
                </TabPanel>
              )}
              {activeTab === 2 && (
                <TabPanel>
                  <Box maxWidth='800px'>
                    <Typography variant="h6" marginBottom={4} sx={{ fontWeight: 'bold' }}>
                      Select User Type
                    </Typography>
                    <RadioGroup
                      name="userType"
                      value={userData.user_type}
                      onChange={handleUserTypeChange}
                    >
                      <FormControlLabel value="admin" control={<Radio />} label="Admin" />
                      <FormControlLabel value="brandAdmin" control={<Radio />} label="Brand Admin" />
                      <FormControlLabel value="brandUser" control={<Radio />} label="Brand User" />
                    </RadioGroup>
                    {(userData.user_type === 'brandAdmin' || userData.user_type === 'brandUser') && (
                      <TextField
                        select
                        label="Select Brand"
                        value={userData.brandId}
                        onChange={handleBrandChange}
                        SelectProps={{
                          native: true,
                        }}
                        fullWidth
                        variant="outlined"
                        sx={{ marginTop: 3 }}
                        InputLabelProps={{ shrink: true }}
                      >
                        <>
                          <option value="">Select a brand</option>
                          {brands.map((brand) => (
                            <option key={brand._id} value={brand._id}>
                              {brand.company_name}
                            </option>
                          ))}
                        </>
                      </TextField>
                    )}
                  </Box>
                </TabPanel>
              )}
          </Box>
        </Container>
      </Box>
    </Box>
  );
};

const TabPanel = (props) => {
  const { children } = props;
  return (
    <Box sx={{ paddingTop: 3 }}>
      {children}
    </Box>
  );
};

export default CreateUserPage;
